<template>
  <div class="push-add">
    <van-form @submit="saveInfo()" validate-trigger="saveInfo" @failed="onFailed" :rules="saveForm">

      <van-image
          width="100%"
          :src="require('@/assets/货运动态.png')"
      />
      <div class="page-card-title">
        <van-row>
          <van-col offset="1">
            <h4>调查问卷</h4>
          </van-col>
          <van-col>
            <van-image
                style="padding-top: 25px"
                width="20px"
                :src="require('@/assets/动图.gif')"
            />
            <van-image
                style="padding-top: 25px"
                width="20px"
                :src="require('@/assets/动图.gif')"
            />
            <van-image
                style="padding-top: 25px"
                width="20px"
                :src="require('@/assets/动图.gif')"
            />
          </van-col>
          <van-col offset="">
            <h6 style="color: red">下滑填写</h6>
          </van-col>
        </van-row>
      </div>
      <van-field label="1、日常是否与客户反馈动态："
                 required
                 >
        <template #input>
          <van-radio-group v-model="checked" direction="horizontal">
            <van-radio name="1" class="checkbox1">是</van-radio>
            <van-radio name="2" class="checkbox1">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <van-field label="2、何种方式："
                 required
                 >
        <template #input>
          <van-checkbox-group v-model="checked1" >
            <van-checkbox shape="square" name="货运动态推送" class="checkbox">货运动态推送</van-checkbox>
            <van-checkbox shape="square" name="电话/微信" class="checkbox">电话/微信</van-checkbox>
            <van-checkbox shape="square" name="客户指定方式" class="checkbox">客户指定方式（说明）</van-checkbox>
            <van-checkbox shape="square" name="其他方式" class="checkbox">其他方式（说明）</van-checkbox>
          </van-checkbox-group>
        </template>
      </van-field>

      <van-row v-if="checked1.includes('客户指定方式')">
        <van-col>
          <van-field v-model="khzdfs"
                     required
                     label="客户指定方式说明："
                     type="textarea"
                     rows="1"
                     autosize
                     input-align="left"
                     :rules="[{ required: true, message: '' }]"
                     placeholder='请填写（说明）'/>

        </van-col>
      </van-row>
      <van-row v-if="checked1.includes('其他方式')">
        <van-col>
          <van-field v-model="qtfs"
                     required
                     label="其他方式说明："
                     type="textarea"
                     rows="1"
                     autosize
                     input-align="left"
                     :rules="[{ required: true, message: '' }]"
                     placeholder='请填写（说明）'/>

        </van-col>
      </van-row>


      <van-row>
        <van-col>
          <van-field v-model="rcfkys"
                     label="3、日常反馈要素："
                     required
                     type="textarea"
                     rows="1"
                     autosize
                     input-align="left"
                     :rules="[{ required: true, message: '' }]"
                     placeholder='请填写（说明）'/>

        </van-col>
      </van-row>


      <van-field label="4、其他定制要求："
                 required
                 >
        <template #input>
          <van-checkbox-group v-model="checked2">
            <van-checkbox shape="square" name="海运" class="checkbox">海运</van-checkbox>
            <van-checkbox shape="square" name="海陆联运" class="checkbox">海陆联运</van-checkbox>
            <van-checkbox shape="square" name="单独陆运" class="checkbox">单独陆运</van-checkbox>
            <van-checkbox shape="square" name="单独过驳" class="checkbox">单独过驳</van-checkbox>
            <van-checkbox shape="square" name="仓储集港" class="checkbox">仓储集港</van-checkbox>
            <van-checkbox shape="square" name="其他" class="checkbox">其他（说明）</van-checkbox>
          </van-checkbox-group>
        </template>
      </van-field>

      <van-row v-if="checked2.includes('其他')">
        <van-col>
          <van-field v-model="qt"
                     required
                     label="其他（说明）"
                     type="textarea"
                     rows="1"
                     autosize
                     input-align="left"
                     :rules="[{ required: true, message: '' }]"
                     placeholder='请填写（说明）'/>

        </van-col>
      </van-row>


      <van-field label="5、是否采用线上动态推送："
                 required
                 >
        <template #input>
          <van-radio-group v-model="checked3" direction="horizontal">
            <van-radio name="1" class="checkbox1">是</van-radio>
            <van-radio name="2" class="checkbox1">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <van-row v-if="checked3.includes('2')">
        <van-col>
          <van-field v-model="dtts"
                     required
                     label="动态推送说明："
                     type="textarea"
                     rows="1"
                     autosize
                     input-align="left"
                     :rules="[{ required: true, message: '' }]"
                     placeholder='请填写（说明）'/>

        </van-col>
      </van-row>

      <van-button type="primary" size="large" class="footer-bar">提交</van-button>
    </van-form>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      checked: '1',
      checked1: [],
      checked2: [],
      checked3: '1',
      khzdfs: '',
      qtfs: '',
      rcfkys: '',
      qt: '',
      dtts: '',
      activeNames: [],
      saveForm: {},
    }
  },
  methods: {
    onFailed(errorInfo) {

      console.log('failed', errorInfo);

    },
    saveInfo() {
      if(this.checked1.length==0){
        this.$message.error('请选择何种方式！');
        return;
      }
      if(this.checked2.length==0){
        this.$message.error('请选择其他定制需求！');
        return;
      }
      let questionnaireTwo = '何种方式:'
      for(var i=0; i<this.checked1.length; i++){
        questionnaireTwo += this.checked1[i]
        if(this.checked1[i] == '客户指定方式'){
          questionnaireTwo += "：" + this.khzdfs +";"
        }else if(this.checked1[i] == '其他方式'){
          questionnaireTwo += "：" + this.qtfs +";"
        }else{
          questionnaireTwo += ";"
        }
      }
      let questionnaireFour = '其他定制需求:'
      for(var i=0; i<this.checked2.length; i++){
        questionnaireFour += this.checked2[i]
        if(this.checked2[i] == '其他'){
          questionnaireFour += "：" + this.qt +";"
        }else{
          questionnaireFour += ";"
        }
      }
      this.$ajax.post('/api/ajax/cusPush/savePushAdd.json',
          {
            questionnaireOne: this.checked == '1' ? '日常是否与客户反馈动态:是' : '日常是否与客户反馈动态:否',
            questionnaireTwo: questionnaireTwo,
            questionnaireThree: '日常反馈动态要素:' + this.rcfkys,
            questionnaireFour: questionnaireFour,
            questionnaireFive: this.checked3 == '1' ? '是否采用线上动态推送:是' : '是否采用线上动态推送:否' + this.dtts,
            creater: this.$store.getters.userId
          }).then(res => {
        if (res.status == 200) {
          this.$message.success('提交成功！');
          setTimeout(() => {
            window.close()
          }, 800);
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('提交失败！');
      })
    }
  }
}
</script>

<style scoped>
.push-add {
  background-color: #FFFFFF;
}

.push-add .checkbox{
  margin-top: 8px;
}

.push-add .checkbox1{
  margin-top: 12px;
}

.push-add .page-card-title{
  text-align: left;
  background-color: #2CD79F;
}
</style>